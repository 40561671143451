.horizontal-menu {
  padding-left: 10px;
}
.menu-item {
  padding: 0;
  margin: 0;
  user-select: none;
  cursor: pointer;
  border: 0px transparent solid;
}
.menu-item-wrapper {
  margin: 0;
  padding: 0;
}
.menu-item-wrapper.active {
  margin: 0;
  padding: 0;
}
.menu-item.active {
  margin: 0;
  padding: 0;
}

.scroll-menu-arrow {
  margin: 0;
  cursor: pointer;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;

  /* display: none; */
}

.section-title {
  margin-bottom: 0;
  padding-left: 10px;
  padding-top: 1em;
  padding-bottom: 0.125em;
  font-size: 2rem;
  width: 180px;
}

.card-wrapper {
  width: 280px;
  margin-right: 1.5em;
}

.card-image {
  width: 280px;
  height: 155px;
}

.card-title {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (min-width: 500px) {
  .card-image {
    width: 400px;
    height: 225px;
  }
  .section-title {
    width: 250px;
  }
  .card-wrapper {
    width: 400px;
  }
}
