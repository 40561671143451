@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  src: local('Gotham'), url(./fonts/Gotham-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  src: local('Gotham'), url(./fonts/Gotham-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  src: local('Gotham'), url(./fonts/Gotham-Book.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 200;
  src: local('Gotham'), url(./fonts/Gotham-Light.otf) format('opentype');
}

body {
  margin: 0;
  font-family: Gotham, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Gotham, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
